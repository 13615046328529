export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCESS";
export const DELETE_USER_OPTIMISTIC = "DELETE_USER_OPTIMISTIC";
// Tasks actions
export const LOAD_TASKS_SUCCESS = "LOAD_TASKS_SUCCESS";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCESS";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCESS";
export const DELETE_TASK_OPTIMISTIC = "DELETE_TASK_OPTIMISTIC";
// API actions
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
